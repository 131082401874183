/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Typography from '@material-ui/core/Typography';
// import Container from '@material-ui/core/Container';
import './App.css';

function App() {
  return (
    <div className="App">
      <Typography variant="h4" paragraph gutterBottom>
        tcfdesign.com
      </Typography>
      <Typography variant="h3" paragraph gutterBottom>
        Coming Soon
      </Typography>
      

    </div>
  );
}

export default App;
